html, body {
  background-color: #fff3e2;
  font-family: "Lora", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  padding: 0;
  margin: 0;
  letter-spacing: 0;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}

#root {
  min-height: 100vh;
  padding-bottom: 2rem;
}

a {
  color: #2d3a3d;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  line-height: 1.2;
}

b {
  font-weight: 900;
}

input {
  outline: none;
}
html {
  font-size: 12px;
}

@media screen and (min-width: 800px) {
  html {
    font-size: 14px;
  }
}
@media screen and (min-width: 1024px) {
  html {
    font-size: 16px;
  }
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.alphabet {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-family: "Lora", serif;
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 2rem;
  margin-right: 2rem;
  gap: 0.4rem;
}
@media screen and (min-width: 1024px) {
  .alphabet {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    gap: 0.2rem;
  }
}

.alphabet-letter {
  width: calc(50% - 0.4rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 0.4rem;
  background-color: rgba(255, 255, 255, 0.7);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 800px) {
  .alphabet-letter {
    width: calc(25% - 1.2rem);
  }
}
@media screen and (min-width: 1024px) {
  .alphabet-letter {
    width: calc(16.6666666667% - 1rem);
  }
}
.alphabet-letter .index {
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  color: rgba(76, 82, 107, 0.3);
  position: absolute;
  top: 0;
  left: 0.2rem;
}
.alphabet-letter .armenian {
  font-size: 3rem;
  font-weight: 500;
  display: flex;
  align-items: flex-end;
  gap: 0.5rem;
}
.alphabet-letter .armenian span:first-child {
  font-size: 4rem;
}
.alphabet-letter .armenian span:last-child {
  font-weight: 300;
  color: #4c526b;
}
.alphabet-letter .russian {
  font-size: 2rem;
  font-weight: 300;
  font-family: "Raleway", sans-serif;
}
.full-case-list-scrollable {
  overflow-x: auto;
  margin-top: 1rem;
  padding-bottom: 1rem;
  scroll-behavior: smooth;
  display: flex;
  -webkit-scroll-snap-type: x mandatory;
          scroll-snap-type: x mandatory;
}
.full-case-list {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}
.full-case-list .full-case {
  scroll-snap-align: center;
}
.full-case-list .full-case:first-child {
  margin-left: 2rem;
}
.full-case-list .full-case:last-child {
  margin-right: 2rem;
}

.full-case-list-scroll-actions {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
}
.full-case-list-scroll-actions a {
  font-size: 2rem;
  color: rgba(45, 58, 61, 0.5);
  transition: color 0.2s;
}
.full-case-list-scroll-actions a:hover {
  color: #2d3a3d;
}
@charset "UTF-8";
.article h3 {
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
}
.article h3 a .icon {
  margin-left: 0.4rem;
  width: 0.7rem;
  height: 0.7rem;
  color: #4c526b;
  transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.article h3::before {
  content: "→";
  margin-right: 0.5rem;
  color: rgba(76, 82, 107, 0.5);
}
.article.expanded a .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.article blockquote {
  font-weight: 300;
  margin: 0.5rem;
  margin-left: 1rem;
}
.article p {
  font-family: "Raleway", sans-serif;
  font-weight: 400;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}
.article p strong {
  font-weight: 600;
}
.article > div {
  margin-left: 1.5rem;
  margin-top: 0.5rem;
}
#chapters-menu-burger {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}
#chapters-menu-burger .icon {
  width: 1.5rem;
  height: 1.5rem;
}
@media screen and (min-width: 800px) {
  #chapters-menu-burger {
    display: none;
  }
}

#chapters-menu {
  font-family: "Raleway", sans-serif;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
@media screen and (min-width: 800px) {
  #chapters-menu {
    position: static;
    margin-top: 1rem;
    width: auto;
  }
}
#chapters-menu ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  justify-content: flex-start;
  gap: 0.5rem;
  background-color: #fff;
  top: 0;
  bottom: 0;
  right: 100%;
  padding: 2rem;
  position: fixed;
  overflow: hidden;
  transition: left 0.2s, right 0.2s;
}
@media screen and (min-width: 800px) {
  #chapters-menu ul {
    position: static;
    flex-direction: row;
    background-color: transparent;
    justify-content: center;
    padding: 0;
  }
}
#chapters-menu li {
  height: 4rem;
  padding: 0 1rem;
  border-radius: 1.2rem;
  transition: background-color 0.2s linear;
  white-space: nowrap;
}
#chapters-menu li:hover {
  background-color: rgba(205, 96, 69, 0.1);
}
#chapters-menu li.active {
  background-color: rgba(205, 96, 69, 0.8);
}
#chapters-menu li.active a {
  color: #fff3e2;
}
#chapters-menu a {
  font-size: 1.2rem;
  font-weight: 300;
  line-height: 4rem;
  color: #2d3a3d;
}
#chapters-menu a.disabled {
  pointer-events: none;
  color: rgba(45, 58, 61, 0.5);
}
#chapters-menu.active ul {
  left: 0;
  right: 0;
}
#chapters-menu.active #chapters-menu-burger {
  position: fixed;
}
.dialog {
  opacity: 0;
  position: fixed;
  background-color: #fff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  padding: 1rem;
  top: 0;
  left: 0;
  transition: opacity 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.dialog.active {
  opacity: 1;
}
.footer {
  margin-top: auto;
  text-align: center;
  font-family: "Raleway", sans-serif;
  font-size: 0.8rem;
}
#header {
  padding-top: 1rem;
}
#header h1 {
  font-family: "Lora", serif;
  color: #2d3a3d;
  text-align: center;
  text-wrap: balance;
  padding: 0 4rem;
}
@media screen and (min-width: 800px) {
  #header h1 {
    padding: 0;
  }
}
#root > .loader {
  width: 10vw;
  aspect-ratio: 1;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.search {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding: 0 2rem;
}
@media screen and (min-width: 800px) {
  .search {
    width: 50vw;
  }
}

.search-word {
  font-family: "Raleway", sans-serif;
  padding: 0.4rem 0.6rem;
  background-color: #fff3e2;
  margin: 0.5rem;
  border-radius: 1rem;
  display: flex;
  align-items: center;
}
.search-word a {
  margin-left: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.search-word a .icon {
  width: 0.8rem;
  height: 0.8rem;
}

.search-field {
  background-color: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: #fff3e2;
  transition: border-color 0.2s linear;
  border-radius: 1rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex: 1;
}
.search-field.focus {
  border-color: #fcd868;
}
.search-field input {
  flex: 1;
  font-family: "Raleway", sans-serif;
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 300;
  padding: 0.8rem;
  height: 3rem;
  border: 0;
  outline: none;
}
.search-field input::-webkit-input-placeholder {
  color: rgba(76, 82, 107, 0.5);
}
.search-field input::placeholder {
  color: rgba(76, 82, 107, 0.5);
}

.search-field__clear {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
}
.search-field__clear .icon {
  width: 1rem;
  height: 1rem;
  color: #cd6045;
}
.word-list > h3 {
  margin-bottom: 0.5rem;
}
.word-list > h3 span.comment {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-style: italic;
  font-weight: 300;
  color: #4c526b;
}
.word-list .more {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 243, 226, 0.8);
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}
.word-list .more .icon {
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  color: #4c526b;
  transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.word-list.expanded .more .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.word {
  border: 1px solid rgba(252, 216, 104, 0.5);
  background-color: #fff3e2;
  border-radius: 0.5rem;
  margin-top: 2px;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.03);
  overflow: hidden;
}
.word.expanded .expand .icon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.word .informal {
  display: inline-block;
}
.word .informal i {
  font-size: 0.8rem;
  margin-right: 0.2rem;
}
.word .informal::before {
  display: inline;
  content: "(";
}
.word .informal::after {
  display: inline;
  content: ")";
}
.word .nominative {
  color: #4c526b;
}
.word .translation {
  color: #4c526b;
  font-style: italic;
}
.word .armenian {
  font-weight: 500;
}
.word .comment {
  color: #4c526b;
  font-size: 0.8rem;
  font-weight: 400;
  font-style: italic;
}
.word .transcription {
  font-family: "Raleway", sans-serif;
  color: #4c526b;
  font-size: 0.9rem;
  display: inline-block;
}
.word .transcription::before {
  display: inline;
  content: "[";
}
.word .transcription::after {
  display: inline;
  content: "]";
}

.word__header {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  position: relative;
  padding: 0.2rem;
  padding-left: 0.5rem;
}
.word__header span, .word__header a {
  margin-right: 0.3rem;
  line-height: 1.2rem;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.word__header a:hover {
  text-decoration: underline;
  -webkit-text-decoration-color: rgba(45, 58, 61, 0.2);
          text-decoration-color: rgba(45, 58, 61, 0.2);
  text-underline-offset: 2px;
}
.word__header .icon {
  width: 0.7rem;
  height: 0.7rem;
  color: #cd6045;
  transition: -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.word__header .actions {
  display: flex;
  gap: 0.2rem;
  margin-left: auto;
  margin-right: 0.2rem;
}
.word__header .actions a {
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.word__info {
  background-color: #fff;
  padding: 0.7rem 0.5rem;
  border-top: 1px solid rgba(252, 216, 104, 0.5);
}
.word__info .pfx {
  font-family: "Raleway", sans-serif;
  color: #4c526b;
  font-weight: 500;
  font-size: 0.5rem;
}
.word__info h5 {
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0;
  margin-top: 1rem;
  color: rgba(45, 58, 61, 0.8);
  margin-bottom: 0.2rem;
}
.word__info h5:first-child {
  margin-top: 0;
}
.word__info .word-form {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.word__examples {
  background-color: #fff;
  padding: 0.5rem;
  border-top: 1px solid rgba(252, 216, 104, 0.5);
}
.word__examples span {
  margin-right: 0.3rem;
}
.full-case {
  background-color: #fff;
  padding: 1rem;
  border-radius: 1rem;
  flex: 1;
  max-width: none;
  min-width: 100vw;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
}
@media screen and (min-width: 800px) {
  .full-case {
    flex: none;
    max-width: 50vw;
    min-width: 30vw;
  }
}
.full-case h2, .full-case h3, .full-case h4 {
  margin-top: 0.7rem;
  margin-bottom: 0.25rem;
  line-height: 1;
}
.full-case p.description {
  font-family: "Raleway", sans-serif;
}

.full-case__qgroup h4 {
  color: #4c526b;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0;
  margin-top: 0.6rem;
  margin-bottom: 0.5rem;
  padding-left: 0.25rem;
}
.full-case__qgroup ul {
  display: flex;
  gap: 0.2rem;
  flex-wrap: wrap;
}
.full-case__qgroup li {
  display: block;
  -webkit-text-decoration-color: #e59c16;
          text-decoration-color: #e59c16;
  font-style: italic;
  letter-spacing: 0;
  border-radius: 0.5rem;
  padding: 0.2rem 0.5rem;
  line-height: 1rem;
  transition: background-color 0.2s;
  background-color: rgba(255, 243, 226, 0.25);
}
.full-case__qgroup li.clickable {
  background-color: #fff3e2;
}
.full-case__qgroup li.active {
  background-color: rgba(205, 96, 69, 0.5);
}
.full-case__qgroup li .comment {
  font-size: 0.9rem;
  display: inline-block;
  margin-left: 5px;
  color: #4c526b;
}
.full-case__qgroup li .comment::before {
  display: inline;
  content: "(";
}
.full-case__qgroup li .comment::after {
  display: inline;
  content: ")";
}

.full-case__declension h3 .comment {
  color: #4c526b;
  font-size: 0.8rem;
  font-weight: 400;
  font-style: italic;
  margin-left: 0.5rem;
}
.full-case__declension h4 {
  color: #4c526b;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.7rem;
  letter-spacing: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.4rem;
  margin-left: 0.6rem;
}

.full-case__group h3 {
  font-size: 1rem;
}

.full-case__articles {
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top: 1px solid rgba(45, 58, 61, 0.15);
  border-bottom: 1px solid rgba(45, 58, 61, 0.15);
}